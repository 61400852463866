.App {
  text-align: center;
  background: #000;
  overflow: hidden;
}
.ant-layout{
  background: #000;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
}

.Toastify__toast {
    background: #000 !important;
    border: 1px solid #00EFFF !important;
     
}
.Toastify__toast > div {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 16.8px;
        color: #fff;
    }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
