.container {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}

.section-feature-banner {
    padding: 60px 0 100px 0;
    padding-top: 184.2px;

    .wrap-feature-banner {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 20px;
        justify-content: space-between;
        flex-wrap: wrap;

        .box-img {
            // margin-bottom: 40px;

            img {
                // width: 940px;
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }

        .title {
            font-family: Radio Canada;
            font-size: 64px;
            font-weight: 600;
            line-height: 76.8px;
            text-align: left;
            margin-bottom: 25px;
            background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .desc {
            font-family: Radio Canada;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.4;
            text-align: left;
            color: #FFFFFFA3;
            margin-bottom: 25px;
        }

        .group-button {
            .btn-launch-app {
                background: rgb(0, 188, 212);
                background: radial-gradient(circle, rgba(0, 188, 212, 1) 0%, rgba(0, 71, 255, 1) 100%);
                cursor: pointer;
                border-radius: 12px;
                height: 48px;
                width: 200px;
                box-sizing: border-box;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid #00efff;
                border-bottom: 0px;
                margin: 0 10px;

                &.res-button {
                    background: #000;
                    border: 1px solid #4484FF
                }
            }
        }
    }
}

.main-title {
    padding-bottom: 40px;

    .desc-m {
        font-family: Radio Canada;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        text-align: center;
        color: #54FFFF;
        margin-bottom: 10px;
    }

    .title-m {
        font-family: Radio Canada;
        font-size: 52px;
        font-weight: 600;
        line-height: 76.8px;
        text-align: center;
        // background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
        background: -webkit-linear-gradient(left, #DDF3FF, #5394FF, #DDF3FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation-duration: 3s;
        animation-name: animationgradienttitle;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
    }

    @keyframes animationgradienttitle {
        0% {
            background-position: 0 1600px;
        }

        100% {
            background-position: 1600px 0;
        }
    }
}

.secion-feature-introducing {
    padding: 60px 0;
    position: relative;

    .row-images-intro {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;

        img {
            width: 400px;
            display: block;
        }
    }

    .wrap-feature-introducing {
        .desc-page {
            font-family: Radio Canada;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.4;
            text-align: center;
            color: #FFFFFFA3;
            margin-bottom: 10px;
        }

        .box-img {
            img {
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

.columns {
    display: flex;
    flex-wrap: wrap;
}

.w-4 {
    width: calc(4 / 12 * 100%);
    z-index: 2;
}

.w-3 {
    width: calc(3 / 12 * 100%);
}

.section-feature-building {
    padding: 5px 0;

    .wrap-feature-building {
        .group-building {
            margin: 0 -5px;

            .page-resize {
                padding: 5px;

                .content-row {
                    border: 1px solid #00efff63;
                    padding: 20px;
                    border-radius: 24px;
                    position: relative;
                    transition: 0.3s ease;
                    cursor: pointer;
                    min-height: 390px;

                    &:hover {
                        border: 1px solid #54FFFF;
                        box-shadow: inset 0 0 16px 0 #54FFFF;

                        .title {
                            color: #54FFFF;
                        }

                        .desc {
                            color: #54FFFF;
                        }
                    }

                    .box-img {
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .title {
                        font-family: Radio Canada;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 28.8px;
                        text-align: left;
                        color: #fff;
                        margin-bottom: 15px;
                        transition: 0.3s ease;
                    }

                    .desc {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-align: left;
                        color: #CDD5DF;
                        transition: 0.3s ease;
                    }
                }
            }
        }
    }
}

.section-feature-empower {
    padding: 60px 0;

    .wrap-feature-empower {
        .group-empower {
            margin: 0 -5px;

            .page-resize {
                padding: 5px;

                .content-row {
                    border: 1px solid #00efff63;
                    padding: 20px;
                    border-radius: 24px;
                    background: #000;
                    transition: 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        border: 1px solid #54FFFF;
                        box-shadow: inset 0 0 16px 0 #54FFFF;

                        .title {
                            color: #54FFFF;
                        }

                        .desc {
                            color: #54FFFF;
                        }

                        .step-row {
                            color: #54FFFF;
                        }
                    }

                    .box-img {
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .step-row {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 19.2px;
                        text-align: left;
                        color: #4484FF;
                        margin-bottom: 15px;
                        transition: 0.3s ease;
                    }

                    .title {
                        font-family: Radio Canada;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28.8px;
                        text-align: left;
                        color: #fff;
                        margin-bottom: 15px;
                        transition: 0.3s ease;
                    }

                    .desc {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-align: left;
                        color: #CDD5DF;
                        transition: 0.3s ease;
                    }
                }
            }
        }
    }
}

.section-feature-optimized {
    padding: 60px 0;
    position: relative;

    .images-op {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        img {
            display: block;
        }
    }

    .wrap-feature-optimized {
        .desc-page {
            font-family: Radio Canada;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.4;
            text-align: center;
            color: #FFFFFFA3;
            margin-bottom: 10px;
        }

        .box-img {
            img {
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

.section-feature-action {
    padding: 60px 0 90px 0;

    .desc-page {
        font-family: Radio Canada;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        text-align: center;
        color: #FFFFFFA3;
        margin-bottom: 60px;
    }

    .wrap-feature-action {
        .group-action {
            margin: 0 -5px;

            .page-resize {
                padding: 5px;
                height: 100%;

                .content-row {
                    background: radial-gradient(53.36% 100% at 50% 99.19%, #000000 0%, rgb(0 0 0) 100%) padding-box, radial-gradient(77.44% 79.5% at 49.92% 85.38%, #00EFFF 0%, rgba(41, 145, 223, 0.690882) 30.91%, rgba(41, 79, 153, 0) 100%) border-box;
                    padding: 20px;
                    border-radius: 24px;
                    border: 1px solid transparent;
                    height: 100%;
                    transition: 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        border: 1px solid #54FFFF;
                        box-shadow: inset 0 0 16px 0 #54FFFF;

                        .title {
                            color: #54FFFF;
                        }

                        .desc {
                            color: #54FFFF;
                        }
                    }

                    .box-img {
                        margin-bottom: 20px;

                        img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .step-row {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 19.2px;
                        text-align: left;
                        color: #4484FF;
                        margin-bottom: 15px;
                    }

                    .title {
                        font-family: Radio Canada;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28.8px;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 15px;
                        transition: 0.3s ease;
                    }

                    .desc {
                        font-family: Radio Canada;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-align: left;
                        color: #CDD5DF;
                        transition: 0.3s ease;
                    }
                }
            }
        }
    }
}

.mar-top-30 {
    margin-top: 30px;
}

.section-feature-ecosystem {
    padding: 10px 0;
    position: relative;

    .wrap-feature-ecosystem {
        .desc-page {
            font-family: Radio Canada;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.4;
            text-align: center;
            color: #FFFFFFA3;
            margin-bottom: 60px;
        }

        .group-ecosystem {
            .title {
                font-family: Radio Canada;
                font-size: 32px;
                font-weight: 600;
                line-height: 38.4px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 40px;
            }

            .list-marquee {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 0;
                flex-wrap: wrap;

                .item {
                    padding: 10px;
                    width: 25%;
                   
                    img {
                        width: 100%;
                        border: 1px solid transparent;
                        transition: 0.3s ease;
                        border-radius: 12px;
                        &:hover {
                            border: 1px solid #54FFFF;
                        }
                    }
                }
            }
        }

        .group-social {
            margin: 0 -5px;

            .colum-resize {
                padding: 5px;

                .content-row {
                    border: 1px solid transparent;
                    height: 100%;
                    transition: 0.3s ease;
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 12px;

                    &:hover {
                        border: 1px solid #54FFFF;
                        box-shadow: inset 0 0 16px 0 #54FFFF;

                        .title-sc {
                            color: #54FFFF;
                        }

                        .desc {
                            color: #54FFFF;
                        }
                    }

                    .box-img {
                        width: 100%;

                        img {
                            width: 222px;
                            display: block;
                            margin: 0 auto;
                        }
                    }

                    .title-sc {
                        font-family: Radio Canada;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 28.8px;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 15px;
                        transition: 0.3s ease;
                    }

                    .desc {
                        font-family: Radio Canada;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-align: center;
                        color: #FFFFFF66;
                        transition: 0.3s ease;
                    }
                }
            }
        }

        .group-mey-network {
            padding-top: 30px;
            padding-left: 150px;
            padding-right: 150px;

            .box-img {
                margin-bottom: 30px;

                img {
                    width: 340px;
                    display: block;
                    margin: 0 auto;
                }
            }

            .title {
                font-family: Radio Canada;
                font-size: 64px;
                font-weight: 600;
                line-height: 76.8px;
                text-align: center;
                margin-bottom: 25px;
                background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .group-button {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn-launch-app {
                    background: rgb(0, 188, 212);
                    background: radial-gradient(circle, rgba(0, 188, 212, 1) 0%, rgba(0, 71, 255, 1) 100%);
                    cursor: pointer;
                    border-radius: 12px;
                    height: 48px;
                    width: 200px;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    border: 1px solid #00efff;
                    border-bottom: 0px;
                    margin: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                        display: block;
                        margin-right: 7px;
                    }

                    &.res-button {
                        width: 250px;
                        background: #4484FF1A;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.com1 img {
    width: 50%;
}

video.video-box {
    width: 500px;
    height: auto;
}

.rotating {
    // -webkit-animation: rotating 2s linear infinite;
    // -moz-animation: rotating 2s linear infinite;
    // -ms-animation: rotating 2s linear infinite;
    // -o-animation: rotating 2s linear infinite;
    // animation: rotating 2s linear infinite;
}

.box-img {
    width: calc(50% - 10px);
    margin: 0 auto;
}

.wrap-dex {
    width: calc(50% - 10px);
}

@media screen and (max-width: 768px) {
    .box-img {
        width: calc(100%);
    }

    .wrap-dex {
        width: calc(100%);
    }

    .wrap-feature-banner {

        flex-direction: column !important;

    }

    video.video-box {
        width: 300px;
        height: auto;
    }

    .item-header-land {
        display: none;
    }

    .content-header-land {
        padding: 10px 0 !important;
    }

    .logo-header-land img {
        width: 150px !important;
    }

    .section-feature-banner .wrap-feature-banner .box-img img {
        width: 100%;
    }

    .section-feature-banner .wrap-feature-banner .title {
        font-size: 32px;
        line-height: 1.4;
    }

    .section-feature-banner .wrap-feature-banner .desc {
        font-size: 16px;
        text-align: center;
    }

    .section-feature-banner .wrap-feature-banner .group-button .btn-launch-app {
        margin: 5px;
    }

    .main-title .desc-m {
        font-size: 15px;
        line-height: 1.4;
    }

    .main-title .title-m {
        font-size: 28px;
        line-height: 1.4;
    }

    .main-title {
        padding-bottom: 20px;
    }

    .secion-feature-introducing .wrap-feature-introducing .desc-page {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .section-feature-banner,
    .section-feature-building,
    .section-feature-empower,
    .section-feature-optimized,
    .section-feature-action {
        padding: 30px 0;
        padding-top: 90px;
    }

    .w-4 {
        width: 100%;
    }

    .w-3 {
        width: 100%;
    }

    .section-feature-optimized .wrap-feature-optimized .box-img img {
        width: 100%;
    }

    .container {
        padding: 0;
    }

    .App {
        padding: 0 10px;
    }

    .section-feature-ecosystem .wrap-feature-ecosystem .group-mey-network {
        padding: 20px;
        padding-bottom: 0px;
    }

    .section-feature-ecosystem .wrap-feature-ecosystem .group-mey-network .title {
        font-size: 32px;
        line-height: 1.4;
    }

    .footer .wrap-footer .footer-left {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .footer .wrap-footer .footer-right {
        width: 100% !important;
    }

    .secion-feature-introducing,
    .section-feature-ecosystem {
        padding: 60px 0 30px 0;
    }

    .section-feature-building .wrap-feature-building .group-building .page-resize .content-row .title {
        font-size: 20px;
        line-height: 1.4;
    }

    .section-feature-building .wrap-feature-building .group-building .page-resize .content-row .desc {
        font-size: 14px;
        line-height: 1.4;
    }

    .section-feature-empower .wrap-feature-empower .group-empower .page-resize .content-row .title {
        font-size: 20px;
        line-height: 1.4;
    }

    .section-feature-empower .wrap-feature-empower .group-empower .page-resize .content-row .desc {
        font-size: 14px;
        line-height: 1.4;
    }

    .section-feature-optimized .wrap-feature-optimized .desc-page {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .section-feature-action .wrap-feature-action .group-action .page-resize .content-row .desc {
        font-size: 14px;
        line-height: 1.4;
    }

    .section-feature-ecosystem .wrap-feature-ecosystem .desc-page {
        font-size: 14px;
        line-height: 1.4;
    }

    .section-feature-ecosystem .wrap-feature-ecosystem .group-ecosystem .title {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .footer .wrap-footer .footer-left .box-img img {
        width: 200px !important;
    }

    .footer .wrap-footer .footer-right .title {
        text-align: left !important;
    }

    .row-input input {
        width: 60% !important;
    }

    .row-input .btn-subscribe {}

    .row-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        // display: none !important;
    }

    // .footer .copy-right {
    //     display: block !important;
    // }
    .footer .copy-right .right .list-menu {
        padding-inline-start: 0;
        gap: 20px;
        // display: none !important;
    }

    .footer .copy-right .right .list-menu li a {
        padding-left: 0px !important;
    }

    .copy-right {
        flex-direction: column;
    }

    .section-feature-action .wrap-feature-action .group-action .page-resize .content-row {
        height: auto;
    }

    .section-feature-action .wrap-feature-action .desc-page {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 20px;
        color: #ffffff7b;
        font-family: Radio Canada;
    }

    .secion-feature-introducing .wrap-feature-introducing .box-img img {
        width: 100%;
    }

    .section-feature-optimized .images-op {
        display: none;
    }

    .section-feature-ecosystem .wrap-feature-ecosystem .group-mey-network .box-img img {
        width: 100%;
    }

    .secion-feature-introducing .row-images-intro img {
        width: 300px;
        display: block;
    }

    .secion-feature-introducing .row-images-intro {
        top: -5px;
    }

    .com1 img {
        width: 100%;
    }
    .section-feature-ecosystem .wrap-feature-ecosystem .group-ecosystem .list-marquee .item {
        width: 50%;
    }
}