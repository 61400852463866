.main-banner-air {
    background: #0237C3;
    border-bottom: 1px solid #FFFFFF1A;
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 999999;
    padding: 12px 0;
    margin: 0 -10px;
    .wrap-air {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-row {
            .text {
                color: #FFFFFF;
                font-family: Radio Canada;
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                
            }
        }
        .right-row {
            .group-button-claim {
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    display: block;
                    margin-left: 10px;
                    cursor: pointer;
                }
                .btn-claim-air {
                    font-family: Radio Canada;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.2px;
                    text-align: center;
                    color: #000;         
                    padding: 10px 20px;
                    border: 0px solid transparent;  
                    border-radius: 8px;         
                    cursor: pointer;  
                }
            }
        }
    }
}
.link-web{
    color: rgb(253 203 0);
}

 @media (max-width: 768px) {
    .wrap-air{
        padding: 0 10px;
    }
    .main-banner-air .wrap-air .left-row .text{
        font-size: 15px;
    }
    .section-feature-ecosystem .wrap-feature-ecosystem .group-ecosystem .list-marquee .item img{
        border-radius: 6px;
    }
 }