.section-airdrop {
    background: url(../../../public/images/bg-coming.png);
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;

    .wrap-page-air {
        padding-top: 160px;

        .box-img {
            img {
                width: 300px;
                display: block;
                margin: 0 auto;
            }
        }

        .title {
            font-family: Radio Canada;
            font-size: 64px;
            font-weight: 600;
            line-height: 76.8px;
            text-align: center;
            margin-bottom: 25px;
            background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 15px;
        }

        .desc {
            font-family: Radio Canada;
            font-size: 24px;
            font-weight: 400;
            line-height: 28.8px;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
        }

        .connect-btn {
            cursor: pointer;
            background: #000;
            border-radius: 12px;
            height: 50px;
            width: 250px;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid #00efff;
            transition: 0.3s ease;
        }

        .join-network {
            margin-top: 60px;

            .text {
                font-family: Radio Canada;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.2px;
                text-align: center;
                color: #FFFFFF99;
                margin-bottom: 20px;
            }

            .list-social {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 20px;
                    margin: 0 7px;
                    display: block;
                }
            }
        }
    }
}

.section-airdrop-release {
    padding: 120px 0 60px 0;

    .wrap--page-air-release {
        .title {
            font-family: Radio Canada;
            font-size: 40px;
            font-weight: 600;
            line-height: 76.8px;
            text-align: center;
            margin-bottom: 25px;
            background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 15px;
        }

        .time-page {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .item {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    display: block;
                    margin-right: 10px;
                }

                font-family: Radio Canada;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF99;

                span {
                    font-family: Radio Canada;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    text-align: center;
                    color: #fff;
                    margin-left: 5px;
                }

                &:first-child {
                    margin-right: 100px;
                }
            }
        }

        .box-claim-page {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .left-claim {
                width: 60%;

                .wave-content {
                    background: #3B3F454D;
                    border: 1px solid #3B3F4533;
                    backdrop-filter: blur(50px);
                    border-radius: 16px;
                    padding: 16px;
                    color: #fff;
                    height: 100%;

                    .res-top {
                        display: flex;
                        flex-wrap: wrap;

                        .fluw {
                            width: 50%;

                            .txt-top {
                                font-family: Radio Canada;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 19.2px;
                                text-align: left;
                                color: #707A8A;
                                margin-bottom: 10px;
                            }

                            .txt-bottom {
                                font-family: Radio Canada;
                                font-size: 20px;
                                font-weight: 500;
                                line-height: 24px;
                                text-align: left;
                                color: #fff;
                            }
                        }
                    }

                    .res-bottom {
                        background: #58585829;
                        padding: 20px;
                        border-radius: 16px;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .text {
                            font-family: Radio Canada;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 19.2px;
                            text-align: left;
                            color: #707A8A;
                            margin-bottom: 15px;
                        }

                        .number-mey {
                            font-family: Radio Canada;
                            font-size: 32px;
                            font-weight: 500;
                            line-height: 38.4px;
                            text-align: left;
                            color: #fff;
                        }
                    }
                }
            }

            .right-claim {
                width: calc(40% - 20px);

                .box-imgs {
                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

.airdrop-modal {
    .box-img {
        margin-bottom: 10px;

        img {
            width: 100px;
        }
    }

    .title-success {
        font-family: Radio Canada;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
        color: #35D53D;
        margin-bottom: 15px;

        &.err {
            color: #F93240;
        }
    }

    .address-user {
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.01em;
        text-align: center;
        color: #fff;
        margin-bottom: 15px;
    }
}

.how-work-it {
    margin-top: 40px;

    .title {
        font-family: Radio Canada;
        font-size: 32px;
        font-weight: 600;
        line-height: 38.4px;
        text-align: center;
        color: #fff;
        margin-bottom: 15px;
    }

    .wrap-work {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: calc(4 / 12 * 100% - 10px);
            border: 1px solid #3B3F4533;
            backdrop-filter: blur(50px);
            background: #3B3F454D;
            padding: 20px;
            border-radius: 16px;

            .top-nest {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 15px;

                .box-img-res {
                    img {
                        width: 30px;
                        display: block;
                    }
                }

                .text {
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: left;
                    color: #2B79F7;
                }
            }

            .bottom-nest {
                font-family: Radio Canada;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                text-align: left;
                color: #fff;
            }
        }
    }
}

@media (max-width: 768px) {
    .section-airdrop-release .wrap--page-air-release .time-page {
        display: block;

        .item {
            width: 100%;
            font-size: 14px;
        }
    }

    .section-airdrop-release .wrap--page-air-release .time-page .item span {
        font-size: 14px;
    }

    .section-airdrop-release .wrap--page-air-release .time-page .item img {
        width: 15px;
    }

    .section-airdrop-release {
        padding: 80px 0 40px 0;
    }

    .section-airdrop-release .wrap--page-air-release .title {
        font-size: 30px;
        margin-bottom: 0;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .left-claim {
        width: 100%;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .right-claim {
        width: 100%;
        margin-top: 10px;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .left-claim .wave-content .res-top .fluw .txt-top {
        font-size: 14px;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .left-claim .wave-content .res-top .fluw {
        width: 100%;
        margin-bottom: 15px;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .left-claim .wave-content .res-top .fluw:last-child {
        margin-bottom: 0;
    }

    .section-airdrop-release .wrap--page-air-release .box-claim-page .left-claim .wave-content .res-top .fluw .txt-bottom {
        font-size: 16px;
    }

    .how-work-it .wrap-work .item {
        width: 100%;
        margin-bottom: 10px;
    }
}

.text-err {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #FFC700;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
        width: 15px;
        display: block;
        margin-right: 5px;
    }

}