
.content-com{
    background: url(../../../public/images/bg-coming.png);
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
}
.title-com {
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    color: #fff;
    padding-top: 250px;
}

.text-com{
    font-family: Radio Canada;
    font-size: 86px;
    font-weight: 600;
    line-height: 103px;
    text-align: center;
    background: linear-gradient(90deg, #DDF3FF 0%, #5394FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 16px 0 57px 0;
    display: flex;
    justify-content: center;
}
.com-back span{
    font-family: Radio Canada;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: rgba(84, 255, 255, 1);

}
.com-back img{
    width: 32px;
    height: 32px;
    display: block;
}
.com-back {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  
}
.com-back:hover{
    cursor: pointer;
    text-decoration: underline;
    color: rgba(84, 255, 255, 1);
}
@media screen and (max-width: 768px) {
    .content-com{
        background: none;
        height: auto;
    }
    .title-com{
        padding-top: 100px;
    }
    .text-com{
        font-size: 60px;
        line-height: 70px;
    }
    .com-back span{
        font-size: 20px;
    }
    .footer.new{
       display: none;
    }
}