

.container-policy {
    padding-top: 120px;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    padding-top: 150px;
    font-family: "Radio Canada", sans-serif;
}
.title1-policy{
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 10px;
}
.dec-policy{
    color: #ffffffb0;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.title2-policy{
     color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}
.dec-policy.cus{
    padding-left: 50px;
}

@media screen and (max-width: 768px) {
    .container-policy{
        padding-top: 100px;
    }
    .title1-policy{
        font-size: 32px;
    }
}