.header-landing {
    background: rgb(0, 0, 0, 90);
    font-family: "Radio Canada", sans-serif;
    border-bottom: 1px solid #ffffff1a;
    width: 100%;
    position: fixed;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
}

.content-header-land {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.header-land-left {
    display: flex;
    gap: 25px;
    align-items: center;
}

.logo-header-land {
    padding-right: 40px;

    img {
        width: 212px;
        display: block;
    }
}

.name-logo-land {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
}

.item-header-land {
    a {
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
        color: #fff;
        transition: 0.3s ease;
        text-decoration: none;
    }
}

.item-header-land a:hover {
    cursor: pointer;
    color: rgb(0, 188, 212);
}

.btn-launch-app {
    background: rgb(0, 188, 212);
    background: radial-gradient(circle,
            rgba(0, 188, 212, 1) 0%,
            rgba(0, 71, 255, 1) 100%);
    border-radius: 12px;
    height: 40px;
    width: 135px;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #00efff;
    // border-bottom: 1px solid transparent;
}

.btn-launch-app:hover {
    cursor: pointer;
    background: #000;
}

.footer-landing {
    background: #000;
}

.content-footer-lan {
    padding: 150px 100px 10px 100px;
}

.lan-item1-img img {
    width: 150px;
    height: 40px;
}

.lan-item1-dec {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
}

.footer-lan-bot {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

.footer-lan-item2 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}

.footer-lan-item3 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
}

.footer-lan-item4 {
    font-family: Radio Canada;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
}

.footer-lan-top {
    display: flex;
    background: rgba(8, 92, 252, 0.1);
    border-top: 1px solid rgba(68, 132, 255, 0.6);
    border-radius: 16px;
}

.lan-item1-img {
    padding-bottom: 24px;
}

.footer-lan-item1 {
    width: 55%;
    text-align: start;
    padding: 30px 60px;
}

.footer-lan-item2 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-item3 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-item4 {
    width: 15%;
    padding: 24px 0;
}

.footer-lan-bot1 {
    display: flex;
    gap: 60px;
}

.footer-lan-item2-line {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 15px;
}

.footer-lan-item2-title {
    padding-bottom: 27px;
}

.footer-lan-item2-line:hover {
    text-decoration: underline;
    transition: 0.3s ease;
    cursor: pointer;
}

.list-icon-footer img {
    width: 25px;
    height: 25px;
}

.list-icon-footer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.list-icon-footer img:hover {
    cursor: pointer;
}

.img-ft img {
    width: 100%;
    max-height: 252px;
}

.footer {
    padding: 80px 0 0 0;
    background: url(../../../public/images/footer_bg.png);
    background-position: bottom;
    background-repeat: no-repeat;

    .wrap-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // border-bottom: 1px solid #ffffff1f;
        margin-bottom: 25px;
        padding-bottom: 25px;

        @media (max-width: 768px) {
            margin: 0;
            padding: 0;
        }

        .title-text {
            margin: 16px 0;
            font-family: "Radio Canada";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            text-align: start;

            @media (max-width: 768px) {
                font-size: 18px;
                text-align: center;
            }
        }

        .footer-left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 24px;

            @media (max-width: 768px) {
                align-items: center;
            }

            .footer-left-top {
                width: 400px;
                margin: 0;

                @media (max-width: 768px) {
                    margin: auto;
                }

                img.logo {
                    height: 32px;
                    display: block;

                    @media (max-width: 768px) {
                        margin: auto;
                    }
                }

                .email-text {
                    display: flex;
                    gap: 4px;
                    align-items: center;

                    @media (max-width: 768px) {
                        justify-content: center;
                    }

                    img {
                        height: 16px;
                        width: 16px;
                    }

                    p {
                        margin: 0;
                        font-family: "Radio Canada";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(255, 255, 255, 0.65);
                    }
                }
            }

            .footer-left-bottom {
                display: flex;
                gap: 10px;

                .element {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    box-sizing: border-box;
                    padding: 3.5px 16px;
                    background: rgba(59, 63, 69, 0.4);
                    border: 1px solid rgba(59, 63, 69, 0.2);
                    border-radius: 12px;
                    cursor: pointer;
                    img {
                        height: 32px;
                        width: 32px;
                    }

                    p {
                        margin: 0;
                        font-family: "Radio Canada";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #ffffff;
                    }
                }
            }

            .list-social {
                display: flex;
                align-items: center;

                .item {
                    padding-right: 20px;
                    cursor: pointer;

                    img {
                        width: 32px;
                        display: block;
                    }
                }
            }
        }

        .footer-right {
            width: 50%;
            min-height: 170px;
            display: flex;
            gap: 60px;
            justify-content: flex-end;
            flex-wrap: wrap;

            .title {
                margin: 0 0 25px 0;

                .title-text {
                    margin: 0;
                }
            }

            .link {
                .link-text:hover {
                    color: #00efff;

                    img {
                        filter: brightness(0) saturate(100%) invert(60%) sepia(100%) saturate(1000%) hue-rotate(180deg) brightness(1.2) contrast(1);
                    }
                }

                .link-text {
                    cursor: pointer;
                    display: flex;
                    align-items: flex-end;
                    margin: 16px 0 0 0;
                    text-align: start;
                    font-family: "Radio Canada";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(255, 255, 255, 0.64);

                    img {
                        height: 16px;
                        width: 16px;
                        margin-top: 1.5px;
                        margin-bottom: 1.5px;
                        margin-right: 8px;
                    }
                }
            }

            .row-input {
                input {
                    width: 300px;
                    height: 40px;
                    border: 1px solid transparent;
                    background: #ffffff0a;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.2px;
                    text-align: left;
                    padding-left: 10px;
                    color: #fff;
                    margin-right: 10px;

                    &:focus {
                        border: 1px solid #4484ff;
                        box-shadow: none;
                        outline: none;
                    }
                }

                .btn-subscribe {
                    width: 100px;
                    height: 40px;
                    border: 1px solid transparent;
                    border: 0px solid transparent;
                    background: #4484ff;
                    font-family: Radio Canada;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.2px;
                    text-align: center;
                    color: #fff;
                    border-radius: 8px;
                }
            }

            @media (max-width: 768px) {
                width: 100%;
                justify-content: center;
                gap: 20px;

                .col {
                    width: 45%;
                    margin-bottom: 12px;

                    .title {
                        margin: 0;
                    }

                    .title-text {
                        text-align: center;
                    }

                    .link-text {
                        margin: 8px;
                        justify-content: center;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .copy-right {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;

        p {
            margin: 14px auto;
            font-family: "Radio Canada";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #797878;
        }

        .left {
            font-family: Radio Canada;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #797878;
        }

        .right {
            .list-menu {
                display: flex;
                align-items: center;

                li {
                    list-style-type: none;

                    a {
                        font-family: Radio Canada;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        color: #f4f0ff;
                        padding-left: 20px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.coming-f .wrap-footer {
    border-bottom: none;
}

.coming-f .footer-left {
    width: 100% !important;
}

.title-cm {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 34px;
}

.coming-f .list-social {
    justify-content: center;
    gap: 20px;
}

.coming-f .item {
    padding-right: 0px !important;
}

.footer.new {
    margin-top: 10px;
    padding-top: 0;
}

.row-input input:disabled {
    cursor: not-allowed;
}

.btn-subscribe.dis {
    cursor: not-allowed;
    background: #001529 !important;
}

.footer.cus {
    padding: 10px 0 0 0;

    .footer-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .copy-right {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wrap-footer {
        border-bottom: none !important;
    }
}

.img-logo-land-mo img {
    height: 28px;
    width: auto !important;
}

.footer .copy-right .right .list-menu li a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.img-logo-land-mo:hover {
    cursor: pointer;
}

.img-logo-land:hover {
    cursor: pointer;
}

.btn-subscribe {
    cursor: pointer;
}

.btn-connect {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box,
        linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    box-shadow: 0px 0px 12px 0px #2B79F7 inset;
    padding: 9.5px 23px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    &.res {
        width: 300px;
    }
    &.dis {
        pointer-events: none;
        background: radial-gradient(53.36% 100% at 50% 99.19%, #000 0%, #000 100%) padding-box,
        linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    }
}

.item-name-wallet {
    margin-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: radial-gradient(53.36% 100% at 50% 99.19%, #00BCD4 0%, #0047FF 100%) padding-box,
    linear-gradient(180deg, #00EFFF 0%, #2B79F7 100%) border-box;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0 10px;
    height: 40px;
    img {
        width: 20px;
        display: block;
        cursor: pointer;
    }
    .text-wallet {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #fff;
        margin-left: 10px;
    }
}
.modal-dis {
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-body {
        padding: 30px;
        text-align: center;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-content {
        background: #000;
        border: 1px solid #00EFFF;
        box-shadow: 0px 0px 12px 0px #2B79F7 inset;
    }

    .ant-modal-header {
        background: #000;
        text-align: center;

        .ant-modal-title {
            font-family: Radio Canada;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4;
            text-align: center;
            color: #fff;
        }
    }

    .btn-connect {
        margin: 0 auto;
    }

    .profile-user {
        text-align: center;
    }
}